@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './styles/_variable.scss';
@import './styles/_customize.scss';

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 65px;
  border-bottom: 1px solid white;
  padding-left: 15px;
  background-color: #73C6D9;
  color: white;
}

img {
  object-fit: cover;
}

.color-active,
.color-1 {
  color: #2A95BF;
}

.color-peding,
.color-0 {
  color: #05acf9;
}

.color-inactive,
.color--1,
.color-2 {
  color: #ed0808;
}

.customer-avatar {
  width: 230px;
  height: 230px;
  border-radius: 10px;
  background-color: #b7b7b7;
  flex-direction: column;
}

.preview-image {
  width: 180px;
  height: 140px;
  border: 1px dashed black;
}
