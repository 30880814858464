$neutral-1: #292c38;
$neutral-2: #565c70;
$neutral-3: #96a0b5;
$neutral-4: #c8cfdb;
$neutral-5: #eceff4;
$neutral-6: #ffffff;

$semantic-1: #e35073;
$semantic-2: #ffefeb;
$semantic-3: #49c89a;
$semantic-4: #a276ff;
$semantic-5: #ffec45;
$semantic-6: #fdf4e0;
$semantic-7: #f5edff;
$semantic-8: #e5fde7;

$secondary-1: #99c0df;
$secondary-2: #dbe9ff;
$secondary-3: #f2f8ff;
$secondary-4: #d8e0ed;
$gradient-1: linear-gradient(180deg, #0522ac 0%, rgba(52, 56, 76, 0) 100%);
$gradient-2: linear-gradient(180deg, rgba(252, 226, 237, 0.38) 10.42%, rgba(44, 44, 44, 0) 100%);
$gradient-3: linear-gradient(180deg, #0522ac 0%, rgba(52, 56, 76, 0) 100%);

$primary-color: #2A95BF;
$success-color: #55b938;
$danger-color: #d65745;
$warning-color: #eac645;